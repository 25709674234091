const CheckMultiplePermission = ({
      userPermissions,
      permissions,
      children
}) => {
      return (
            userPermissions &&
            Array.isArray(userPermissions) &&
            permissions.some((permission) =>
                  userPermissions.includes(permission)
            ) &&
            children
      );
};

export default CheckMultiplePermission;
