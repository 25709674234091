import React, { useState, useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import CardHeader from '../../components/common/CardHeader';
import CardBody from '../../components/common/CardBody';

import axios from 'axios';
import Progress from 'react-progress-2';
import toast from 'react-hot-toast';
import ProductImage from '../../components/common/ProductImage';
import ViewButton from '../../components/common/ViewButton';
import DeleteButton from '../../components/common/DeleteButton';
import { Link } from 'react-router-dom';
import { DownloadIcon } from '../../components/icons';

const CandidateList = () => {
      const [loading, setLoading] = useState(false);
      const [candidates, setCandidates] = useState([]);
      const [totalItems, setTotalItems] = useState(0);
      const [perPages, setPerPages] = useState(0);
      const [currentPage, setCurrentPage] = useState(0);

      const token = JSON.parse(localStorage.getItem('token'));
      const [limit, setLimit] = useState(10);
      const [search, setSearch] = useState('');

      const loadData = async () => {
            setLoading(true);
            axios.get(
                  `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/candidates?limit=${limit}&search=${search}`,
                  {
                        headers: {
                              Authorization: `Bearer ${token}`
                        }
                  }
            )
                  .then((response) => {
                        setCandidates(response.data.candidates);
                        setTotalItems(response.data.totalItems);
                        setPerPages(response.data.itemsCountPerPage);
                        setCurrentPage(response.data.currentPage);
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };
      useEffect(() => {
            loadData();
      }, [limit, search]);

      async function getData(pageNumber = 1) {
            window.scrollTo(0, 0);
            setLoading(true);
            Progress.show();
            const url = `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/candidates?page=${pageNumber}&limit=${limit}&search=${search}`;
            await axios(url, {
                  headers: {
                        Authorization: `Bearer ${token}`
                  }
            })
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setCandidates(response.data.candidates);
                              setTotalItems(response.data.totalItems);
                              setPerPages(response.data.itemsCountPerPage);
                              setCurrentPage(response.data.currentPage);
                        }
                        Progress.hide();
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      }

      const handleDelete = async (candidate) => {
            const response = await axios
                  .delete(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/candidates/${candidate.slug}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setCandidates((candidates) =>
                                    candidates.filter(
                                          (item) => item.id !== candidate.id
                                    )
                              );
                              loadData();
                              toast.success(response.data.message);
                              return true;
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            return response;
      };

      return (
            <>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader title="Candidate List" />
                                    <CardBody
                                          setLimit={setLimit}
                                          perPages={perPages}
                                          totalItems={totalItems}
                                          currentPage={currentPage}
                                          getData={getData}
                                          setSearch={setSearch}
                                    >
                                          <thead>
                                                <tr>
                                                      <th>#</th>
                                                      <th>Title</th>
                                                      <th>Qualification</th>
                                                      <th>Apply For</th>
                                                      <th>Phone</th>
                                                      <th width="120px">
                                                            Action
                                                      </th>
                                                </tr>
                                          </thead>
                                          <tbody>
                                                {!loading ? (
                                                      candidates?.length > 0 ? (
                                                            candidates.map(
                                                                  (
                                                                        candidate,
                                                                        index
                                                                  ) => (
                                                                        <>
                                                                              <tr
                                                                                    key={
                                                                                          index
                                                                                    }
                                                                              >
                                                                                    <td className="text-center">
                                                                                          {
                                                                                                ++index
                                                                                          }
                                                                                    </td>
                                                                                    <td>
                                                                                          <ProductImage
                                                                                                title={
                                                                                                      candidate.name
                                                                                                }
                                                                                                image={
                                                                                                      candidate.image
                                                                                                }
                                                                                                category={`From: ${candidate.current_address}`}
                                                                                          />
                                                                                    </td>
                                                                                    <td>
                                                                                          <ProductImage
                                                                                                title={
                                                                                                      candidate.education
                                                                                                }
                                                                                                category={`Experience: ${candidate.experience}`}
                                                                                          />
                                                                                    </td>
                                                                                    <td>
                                                                                          <ProductImage
                                                                                                title={
                                                                                                      candidate.post_name
                                                                                                }
                                                                                                category={`Apply On: ${candidate.date}`}
                                                                                          />
                                                                                    </td>
                                                                                    <td>
                                                                                          {
                                                                                                candidate.phone_number
                                                                                          }
                                                                                    </td>
                                                                                    <td className="text-center">
                                                                                          <Link
                                                                                                href={
                                                                                                      candidate.resume
                                                                                                }
                                                                                                style={{
                                                                                                      width: '20px',
                                                                                                      height: '22px',
                                                                                                      verticalAlign:
                                                                                                            'middle',
                                                                                                      display: 'inline-block'
                                                                                                }}
                                                                                                download={
                                                                                                      true
                                                                                                }
                                                                                          >
                                                                                                <DownloadIcon />
                                                                                          </Link>
                                                                                          <ViewButton
                                                                                                link={`/dashboard/candidates/${candidate.slug}/show`}
                                                                                          />
                                                                                          <DeleteButton
                                                                                                item={
                                                                                                      candidate
                                                                                                }
                                                                                                handleDelete={
                                                                                                      handleDelete
                                                                                                }
                                                                                                loading={
                                                                                                      loading
                                                                                                }
                                                                                                setLoading={
                                                                                                      setLoading
                                                                                                }
                                                                                          />
                                                                                    </td>
                                                                              </tr>
                                                                        </>
                                                                  )
                                                            )
                                                      ) : (
                                                            <tr>
                                                                  <td
                                                                        colSpan={
                                                                              6
                                                                        }
                                                                        className="text-center"
                                                                  >
                                                                        No
                                                                        candidate
                                                                        Found
                                                                  </td>
                                                            </tr>
                                                      )
                                                ) : (
                                                      <>
                                                            <tr>
                                                                  <td
                                                                        colSpan={
                                                                              6
                                                                        }
                                                                        className="text-center"
                                                                  >
                                                                        Loading...
                                                                  </td>
                                                            </tr>
                                                      </>
                                                )}
                                          </tbody>
                                    </CardBody>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default CandidateList;
