import styled from 'styled-components';

export const FormContainer = styled.div`
      padding: 20px;
      background-color: ${(props) => props.theme.primaryColor};
      height: 100vh;
      width: 100vw;
      overflow: hidden;

      .container {
            height: 100%;
      }

      .row {
            align-items: center;
            justify-content: center;
            height: 100%;
      }
`;

export const FormBox = styled.div`
      border-radius: 5px;
      background-color: ${(props) => props.theme.white};
      padding: 1.5rem;
`;

export const FormFooter = styled.div`
      font-size: 0.875rem;
      color: #bebebe;
      margin-top: 3rem;
      display: flex;
      justify-content: center;
      margin-bottom: 0.5rem;

      a {
            color: ${(props) => props.theme.primaryColor};
            font-weight: 500;
            font-size: 15px;
      }
`;

export const FormButton = styled.div`
      button {
            color: ${(props) => props.theme.white};
            background-color: ${(props) => props.theme.primaryColor};
            border-radius: 1px;
            padding: 10px;
            border: none;

            &:hover {
                  background-color: ${(props) => props.theme.secondaryColor};
            }
      }
`;
