import React from 'react';
import { Link } from 'react-router-dom';
import { EditIcon } from '../../icons';

const EditButton = ({ link, classes }) => {
      return (
            <>
                  <Link to={link} className={`text-secondary ${classes}`}>
                        <EditIcon />
                  </Link>
            </>
      );
};

export default EditButton;
