import React from 'react';

const ProductIcon = () => {
      return (
            <>
                  <span className="material-symbols-outlined">inventory</span>
            </>
      );
};

export default ProductIcon;
