import React from 'react';
import Header from '../header';
import Navigation from '../navigation';
import '../style.css';
import { Dashboard } from './styles';

const PrivateLayout = ({ children }) => {
      return (
            <>
                  <Dashboard>
                        <Navigation />
                        <Header children={children} />
                  </Dashboard>
            </>
      );
};

export default PrivateLayout;
