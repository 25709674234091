import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import Spinner from 'react-bootstrap/Spinner';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { Card, Col, Form, Row } from 'react-bootstrap';
import CardHeader from '../../components/common/CardHeader';
import LinkButton from '../../components/common/LinkButton';
import {
      FormButton,
      FormImage,
      FormInput,
      FormStatusSelect,
      FormTextarea
} from '../../components/form';

const TestimonialCreate = () => {
      const [fullName, setFullName] = useState('');
      const [compnayName, setCompanyName] = useState('');
      const [position, setPosition] = useState('');
      const [message, setMessage] = useState('');
      const [image, setImage] = useState('');
      const [imageUrl, setImageUrl] = useState('');
      const [status, setStatus] = useState('');
      const [loading, setLoading] = useState(false);

      const handleFormSubmit = async (event) => {
            event.preventDefault();
            setLoading(true);
            const data = {
                  full_name: fullName,
                  company_name: compnayName,
                  position: position,
                  message: message,
                  image: image,
                  status: status
            };
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/testimonials`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`,
                                    'Content-Type': 'multipart/form-data'
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              toast.success(response.data.message);
                              $('form').trigger('reset');
                              setImageUrl('');
                        }
                        if (response.data.result === 'error') {
                              if (response.data.message.full_name) {
                                    toast.error(
                                          response.data.message.full_name
                                    );
                              }
                              if (response.data.message.company_name) {
                                    toast.error(
                                          response.data.message.company_name
                                    );
                              }
                              if (response.data.message.position) {
                                    toast.error(response.data.message.position);
                              }
                              if (response.data.message.message) {
                                    toast.error(response.data.message.message);
                              }
                              if (response.data.message.image) {
                                    toast.error(response.data.message.image);
                              }
                              if (response.data.message.status) {
                                    toast.error(response.data.message.status);
                              }
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      return (
            <>
                  <Form onSubmit={handleFormSubmit}>
                        <Row>
                              <Col lg={12}>
                                    <Card>
                                          <CardHeader title="Create New Testimonial">
                                                <LinkButton
                                                      link="/dashboard/testimonials"
                                                      title="Back"
                                                />
                                          </CardHeader>
                                          <Card.Body>
                                                <Row className="g-3">
                                                      <FormInput
                                                            title="Full Name"
                                                            required={true}
                                                            handleChange={
                                                                  setFullName
                                                            }
                                                      />
                                                      <FormStatusSelect
                                                            title="Status"
                                                            setStatus={
                                                                  setStatus
                                                            }
                                                      />
                                                      <FormInput
                                                            title="Company Name"
                                                            required={true}
                                                            handleChange={
                                                                  setCompanyName
                                                            }
                                                      />
                                                      <FormInput
                                                            title="Position"
                                                            required={true}
                                                            handleChange={
                                                                  setPosition
                                                            }
                                                      />
                                                      <FormTextarea
                                                            title="Message"
                                                            required={true}
                                                            handleChange={
                                                                  setMessage
                                                            }
                                                      />
                                                </Row>
                                          </Card.Body>
                                    </Card>

                                    <Card className="mt-4">
                                          <Card.Body>
                                                <Row>
                                                      <FormImage
                                                            title="Featured Image"
                                                            setImage={setImage}
                                                            setImageUrl={
                                                                  setImageUrl
                                                            }
                                                            required={true}
                                                            imageUrl={imageUrl}
                                                      />
                                                </Row>
                                          </Card.Body>
                                    </Card>

                                    <Card className="mt-4 mb-5">
                                          <Card.Body>
                                                <Col lg={12}>
                                                      <FormButton
                                                            title="Submit"
                                                            loading={loading}
                                                      />
                                                </Col>
                                          </Card.Body>
                                    </Card>
                              </Col>
                        </Row>
                  </Form>
            </>
      );
};

export default TestimonialCreate;
