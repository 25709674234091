import React from 'react';
import { Col, Form } from 'react-bootstrap';

const FormSelect = ({
      classes,
      title,
      required,
      setData,
      loading,
      options,
      selected,
      selectNone
}) => {
      return (
            <>
                  <Col lg={classes ? classes : 6}>
                        <Form.Label>
                              {title}{' '}
                              {required && (
                                    <span className="text-danger">*</span>
                              )}
                        </Form.Label>
                        <Form.Select
                              value={selected}
                              onChange={(event) => setData(event.target.value)}
                        >
                              <option>Select One</option>
                              {selectNone && (
                                    <option value="none">Without brand</option>
                              )}
                              {!loading ? (
                                    options.length > 0 ? (
                                          options.map((option, index) => (
                                                <option
                                                      key={index}
                                                      value={option.id}
                                                >
                                                      {option.title}
                                                </option>
                                          ))
                                    ) : (
                                          <option>No data found</option>
                                    )
                              ) : (
                                    <option>Loading</option>
                              )}
                        </Form.Select>
                  </Col>
            </>
      );
};

export default FormSelect;
