import React, { useState, useEffect } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';

import $ from 'jquery';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import CardHeader from '../../components/common/CardHeader';
import {
      FormButton,
      FormImage,
      FormInput,
      FormSelect
} from '../../components/form';
import LinkButton from '../../components/common/LinkButton';

const UserCreate = () => {
      const [roles, setRoles] = useState([]);
      const [loadingData, setLoadingData] = useState(false);

      const [name, setName] = useState('');
      const [email, setEmail] = useState('');
      const [password, setPassword] = useState('');
      const [confirmPassword, setConfirmPassword] = useState('');
      const [phoneNumber, setPhoneNumber] = useState('');
      const [image, setImage] = useState('');
      const [imageUrl, setImageUrl] = useState('');
      const [role, setRole] = useState('');
      const [loading, setLoading] = useState(false);

      const token = JSON.parse(localStorage.getItem('token'));

      const loadData = async () => {
            setLoadingData(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/users/roles/list`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setRoles(response.data.roles);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoadingData(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      const handleForm = async (event) => {
            event.preventDefault();
            setLoading(true);
            const data = {
                  name: name,
                  email: email,
                  phone_number: phoneNumber,
                  password: password,
                  password_confirmation: confirmPassword,
                  image: image,
                  role: role
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/users`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                                    'Content-Type': 'multipart/form-data'
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              toast.success(response.data.message);
                              $('form').trigger('reset');
                        }
                        if (response.data.result === 'error') {
                              if (response.data.message.name) {
                                    toast.error(response.data.message.name);
                              }
                              if (response.data.message.email) {
                                    toast.error(response.data.message.email);
                              }
                              if (response.data.message.phone_number) {
                                    toast.error(
                                          response.data.message.phone_number
                                    );
                              }
                              if (response.data.message.password) {
                                    toast.error(response.data.message.password);
                              }
                              if (response.data.message.image) {
                                    toast.error(response.data.message.image);
                              }
                              if (response.data.message.role) {
                                    toast.error(response.data.message.role);
                              }
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      return (
            <>
                  <Form onSubmit={handleForm}>
                        <Row>
                              <Col lg={12}>
                                    <Card>
                                          <CardHeader title="Create New User">
                                                <LinkButton
                                                      title="Back"
                                                      link="/dashboard/users"
                                                />
                                          </CardHeader>
                                          <Card.Body>
                                                <Row className="g-3">
                                                      <FormInput
                                                            title="Full Name"
                                                            classes={8}
                                                            required={true}
                                                            value={name}
                                                            handleChange={
                                                                  setName
                                                            }
                                                      />
                                                      <FormSelect
                                                            loading={
                                                                  loadingData
                                                            }
                                                            classes={4}
                                                            title="Role"
                                                            setData={setRole}
                                                            required={true}
                                                            selected={role}
                                                            options={roles}
                                                      />
                                                      <FormInput
                                                            title="Email"
                                                            required={true}
                                                            value={email}
                                                            handleChange={
                                                                  setEmail
                                                            }
                                                      />
                                                      <FormInput
                                                            title="Phone Number"
                                                            value={phoneNumber}
                                                            handleChange={
                                                                  setPhoneNumber
                                                            }
                                                      />
                                                      <FormInput
                                                            title="Password"
                                                            type="password"
                                                            required={true}
                                                            handleChange={
                                                                  setPassword
                                                            }
                                                      />
                                                      <FormInput
                                                            title="Confirm Password"
                                                            type="password"
                                                            required={true}
                                                            handleChange={
                                                                  setConfirmPassword
                                                            }
                                                      />
                                                </Row>
                                          </Card.Body>
                                    </Card>
                                    <Card className="mt-4">
                                          <Card.Body>
                                                <Row className="g-3">
                                                      <FormImage
                                                            title="Featured Image"
                                                            setImage={setImage}
                                                            setImageUrl={
                                                                  setImageUrl
                                                            }
                                                            required={false}
                                                            imageUrl={imageUrl}
                                                      />
                                                </Row>
                                          </Card.Body>
                                    </Card>
                                    <Card className="mt-4 mb-5">
                                          <Card.Body>
                                                <Row>
                                                      <Col lg={12}>
                                                            <FormButton
                                                                  title="Submit"
                                                                  loading={
                                                                        loading
                                                                  }
                                                            />
                                                      </Col>
                                                </Row>
                                          </Card.Body>
                                    </Card>
                              </Col>
                        </Row>
                  </Form>
            </>
      );
};

export default UserCreate;
