import React, { useState, useEffect } from 'react';

import axios from 'axios';
import { useParams } from 'react-router-dom';
import ListGroup from 'react-bootstrap/ListGroup';
import CardHeader from '../../components/common/CardHeader';
import { Card, Col, Row } from 'react-bootstrap';
import LinkButton from '../../components/common/LinkButton';
import { ItemShow } from '../../components/form';
import toast from 'react-hot-toast';

const UserShow = () => {
      const { userId } = useParams();
      const [user, setUser] = useState([]);

      const loadData = async () => {
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/users/${userId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`,
                                    'Content-Type': 'multipart/form-data'
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setUser(response.data.user);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader title="user Detail">
                                          <LinkButton
                                                link="/dashboard/users"
                                                title="Back"
                                          />
                                    </CardHeader>
                                    <Card.Body>
                                          <Row>
                                                {user?.image && (
                                                      <Col lg={4}>
                                                            <Card>
                                                                  <Card.Body className="text-center">
                                                                        <img
                                                                              className="w-100"
                                                                              src={`${user.image}`}
                                                                        />
                                                                  </Card.Body>
                                                            </Card>
                                                      </Col>
                                                )}
                                                <Col lg="8">
                                                      <ListGroup>
                                                            <ItemShow
                                                                  title="Full Name"
                                                                  value={
                                                                        user.name
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Email: "
                                                                  value={
                                                                        user.email
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Phone: "
                                                                  value={
                                                                        user.phone_number
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Role: "
                                                                  value={
                                                                        user.role
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Date "
                                                                  value={
                                                                        user.date
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Status"
                                                                  value={
                                                                        user.status ? (
                                                                              <>
                                                                                    <span className="text-success">
                                                                                          Active
                                                                                    </span>
                                                                              </>
                                                                        ) : (
                                                                              <>
                                                                                    <span className="text-warning">
                                                                                          Disable
                                                                                    </span>
                                                                              </>
                                                                        )
                                                                  }
                                                            />
                                                      </ListGroup>
                                                </Col>
                                          </Row>
                                    </Card.Body>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default UserShow;
