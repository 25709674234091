import React, { useState, useEffect } from 'react';
import { Card, Col, Form, Row, Table } from 'react-bootstrap';

import Progress from 'react-progress-2';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import CardHeader from '../../components/common/CardHeader';
import CardBody from '../../components/common/CardBody';
import EditButton from '../../components/common/EditButton';
import ViewButton from '../../components/common/ViewButton';
import DeleteButton from '../../components/common/DeleteButton';
import ProductImage from '../../components/common/ProductImage';
import LinkButton from '../../components/common/LinkButton';

const JobList = () => {
      const [loading, setLoading] = useState(false);
      const [jobs, setJobs] = useState([]);
      const [totalJobs, setTotalJobs] = useState(0);
      const [perPages, setPerPages] = useState(0);
      const [currentPage, setCurrentPage] = useState(0);

      const token = JSON.parse(localStorage.getItem('token'));
      const [limit, setLimit] = useState(10);

      const loadData = async () => {
            setLoading(true);
            await axios(
                  `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/job_lists?limit=${limit}`,
                  {
                        headers: {
                              Authorization: `Bearer ${token}`
                        }
                  }
            )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setJobs(response.data.jobs);
                              setTotalJobs(response.data.totalJobs);
                              setPerPages(response.data.itemsCountPerPage);
                              setCurrentPage(response.data.currentPage);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };
      useEffect(() => {
            loadData();
      }, [limit]);

      async function getJobData(pageNumber = 1) {
            window.screenTop(0, 0);
            Progress.show();
            const url = `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/job_lists?page=${pageNumber}`;
            await axios(url, {
                  headers: {
                        Authorization: `Bearer ${token}`
                  }
            }).then((response) => {
                  setJobs(response.data.jobs);
                  setTotalJobs(response.data.totalJobs);
                  setPerPages(response.data.itemsCountPerPage);
                  setCurrentPage(response.data.currentPage);
                  Progress.hide();
            });
      }

      const handleStatus = async (job) => {
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/job_lists/${job.slug}/status`,
                        {},
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              toast.success(response.data.message);
                        }
                        if (response.data.result === 'error') {
                              toast.error(response.data.message);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };

      const handleDelete = async (job) => {
            const response = await axios
                  .delete(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/job_lists/${job.slug}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setJobs((jobs) =>
                                    jobs.filter((item) => item.id !== job.id)
                              );
                              loadData();
                              toast.success(response.data.message);
                              return true;
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            return response;
      };

      return (
            <>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader title="Job List">
                                          <LinkButton
                                                title="Add New"
                                                link="/dashboard/jobs/create"
                                          />
                                    </CardHeader>
                                    <CardBody setLimit={setLimit}>
                                          <thead>
                                                <tr>
                                                      <th
                                                            className="text-center"
                                                            width="60px"
                                                      >
                                                            #
                                                      </th>
                                                      <th>Job Title</th>
                                                      <th>Vacant</th>
                                                      <th width="120px">
                                                            Salary
                                                      </th>
                                                      <th width="120px">
                                                            Deadline
                                                      </th>
                                                      <th width="120px">
                                                            Status
                                                      </th>
                                                      <th width="120px">
                                                            Action
                                                      </th>
                                                </tr>
                                          </thead>
                                          <tbody>
                                                {!loading ? (
                                                      jobs.length > 0 ? (
                                                            jobs.map(
                                                                  (
                                                                        job,
                                                                        index
                                                                  ) => (
                                                                        <>
                                                                              <tr
                                                                                    key={
                                                                                          index
                                                                                    }
                                                                              >
                                                                                    <td className="text-center">
                                                                                          {
                                                                                                job.index
                                                                                          }
                                                                                    </td>
                                                                                    <td>
                                                                                          <ProductImage
                                                                                                title={
                                                                                                      job.title
                                                                                                }
                                                                                                image={
                                                                                                      job.image
                                                                                                }
                                                                                                category={
                                                                                                      job.type
                                                                                                }
                                                                                          />
                                                                                    </td>
                                                                                    <td>
                                                                                          {
                                                                                                job.no_of_vacancy
                                                                                          }
                                                                                    </td>
                                                                                    <td>
                                                                                          {
                                                                                                job.salary
                                                                                          }
                                                                                    </td>
                                                                                    <td>
                                                                                          {
                                                                                                job.deadline
                                                                                          }
                                                                                    </td>
                                                                                    <td>
                                                                                          <Form.Check
                                                                                                type="switch"
                                                                                                id="custom-switch"
                                                                                                defaultChecked={
                                                                                                      job.status ===
                                                                                                      1
                                                                                                            ? true
                                                                                                            : false
                                                                                                }
                                                                                                onClick={() =>
                                                                                                      handleStatus(
                                                                                                            job
                                                                                                      )
                                                                                                }
                                                                                          />
                                                                                    </td>
                                                                                    <td className="text-center">
                                                                                          <EditButton
                                                                                                link={`/dashboard/jobs/${job.slug}/edit`}
                                                                                          />
                                                                                          <ViewButton
                                                                                                link={`/dashboard/jobs/${job.slug}/show`}
                                                                                          />
                                                                                          <DeleteButton
                                                                                                item={
                                                                                                      job
                                                                                                }
                                                                                                setLoading={
                                                                                                      setLoading
                                                                                                }
                                                                                                loading={
                                                                                                      loading
                                                                                                }
                                                                                                handleDelete={
                                                                                                      handleDelete
                                                                                                }
                                                                                          />
                                                                                    </td>
                                                                              </tr>
                                                                        </>
                                                                  )
                                                            )
                                                      ) : (
                                                            <>
                                                                  <tr>
                                                                        <td
                                                                              colSpan="7"
                                                                              className="text-center"
                                                                        >
                                                                              No
                                                                              Job
                                                                              Found.
                                                                        </td>
                                                                  </tr>
                                                            </>
                                                      )
                                                ) : (
                                                      <>
                                                            <tr>
                                                                  <td
                                                                        colSpan="7"
                                                                        className="text-center"
                                                                  >
                                                                        Loading...
                                                                  </td>
                                                            </tr>
                                                      </>
                                                )}
                                          </tbody>
                                    </CardBody>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default JobList;
