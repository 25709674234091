import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';

import Progress from 'react-progress-2';
import { toast } from 'react-hot-toast';
import CardHeader from '../../components/common/CardHeader';
import FaqListBox from '../../components/backend/FaqListBox';
import CardBody from '../../components/common/CardBody';
import ProductImage from '../../components/common/ProductImage';
import FormStatus from '../../components/form/FormStatus';
import EditButton from '../../components/common/EditButton';
import ViewButton from '../../components/common/ViewButton';
import DeleteButton from '../../components/common/DeleteButton';

const FaqRequestList = () => {
      const [loading, setLoading] = useState(false);
      const [faqs, setFaqs] = useState([]);
      const [totalData, setTotalData] = useState(0);
      const [perPages, setPerPages] = useState(0);
      const [currentPage, setCurrentPage] = useState(0);

      const token = JSON.parse(localStorage.getItem('token'));
      const [limit, setLimit] = useState(10);
      const [search, setSearch] = useState('');

      const fetchFaqs = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/faq_requests?limit=${limit}&search=${search}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        setFaqs(response.data.faqs);
                        setTotalData(response.data.totalFaqs);
                        setPerPages(response.data.perPages);
                        setCurrentPage(response.data.currentPage);
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            fetchFaqs();
      }, [limit, search]);

      async function getData(pageNumber = 1) {
            window.scrollTo(0, 0);
            setLoading(true);
            Progress.show();
            const url = `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/faqs?page=${pageNumber}&limit=${limit}&search=${search}`;
            await axios(url, {
                  headers: {
                        Authorization: `Bearer ${token}`
                  }
            })
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setFaqs(response.data.faqs);
                              setTotalData(response.data.totalFaqs);
                              setPerPages(response.data.perPages);
                              setCurrentPage(response.data.currentPage);
                        }
                        Progress.hide();
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      }

      const handleStatus = async (faq) => {
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/faq_requests/${faq.slug}/status`,
                        {},
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              toast.success(response.data.message);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };

      const handleDelete = async (faq) => {
            const response = await axios
                  .delete(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/faq_requests/${faq.slug}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setFaqs((faqs) =>
                                    faqs.filter((item) => item.id !== faq.id)
                              );
                              fetchFaqs();
                              toast.success(response.data.message);
                              return true;
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            return response;
      };

      return (
            <>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader title="Faq Request List" />
                                    <CardBody
                                          totalItems={totalData}
                                          perPages={perPages}
                                          setLimit={setLimit}
                                          currentPage={currentPage}
                                          getData={getData}
                                          setSearch={setSearch}
                                    >
                                          <thead>
                                                <tr>
                                                      <th
                                                            className="text-center"
                                                            width="80px"
                                                      >
                                                            #
                                                      </th>
                                                      <th>Question</th>
                                                      <th>Category</th>
                                                      <th width="130px">
                                                            Date
                                                      </th>
                                                      <th width="80px">
                                                            Status
                                                      </th>
                                                      <th width="120px">
                                                            Action
                                                      </th>
                                                </tr>
                                          </thead>

                                          <tbody>
                                                {!loading ? (
                                                      faqs.length > 0 ? (
                                                            faqs.map(
                                                                  (
                                                                        item,
                                                                        index
                                                                  ) => (
                                                                        <>
                                                                              <tr
                                                                                    key={
                                                                                          index
                                                                                    }
                                                                              >
                                                                                    <td className="text-center">
                                                                                          {
                                                                                                item.index
                                                                                          }
                                                                                    </td>
                                                                                    <td>
                                                                                          <ProductImage
                                                                                                title={
                                                                                                      item.question
                                                                                                }
                                                                                                category={`By: ${item.name}`}
                                                                                          />
                                                                                    </td>
                                                                                    <td>
                                                                                          {
                                                                                                item.category
                                                                                          }
                                                                                    </td>
                                                                                    <td>
                                                                                          {
                                                                                                item.date
                                                                                          }
                                                                                    </td>
                                                                                    <td className="text-center">
                                                                                          <FormStatus
                                                                                                item={
                                                                                                      item
                                                                                                }
                                                                                                handleStatus={
                                                                                                      handleStatus
                                                                                                }
                                                                                          />
                                                                                    </td>
                                                                                    <td className="text-center">
                                                                                          <EditButton
                                                                                                link={`/dashboard/faqs/requests/${item.slug}/edit`}
                                                                                          />
                                                                                          <ViewButton
                                                                                                link={`/dashboard/faqs/requests/${item.slug}/show`}
                                                                                          />
                                                                                          <DeleteButton
                                                                                                item={
                                                                                                      item
                                                                                                }
                                                                                                loading={
                                                                                                      loading
                                                                                                }
                                                                                                setLoading={
                                                                                                      setLoading
                                                                                                }
                                                                                                handleDelete={
                                                                                                      handleDelete
                                                                                                }
                                                                                          />
                                                                                    </td>
                                                                              </tr>
                                                                        </>
                                                                  )
                                                            )
                                                      ) : (
                                                            <>
                                                                  <tr>
                                                                        <td
                                                                              colSpan={
                                                                                    6
                                                                              }
                                                                              className="text-center"
                                                                        >
                                                                              No
                                                                              Faq
                                                                              Request
                                                                              Found
                                                                        </td>
                                                                  </tr>
                                                            </>
                                                      )
                                                ) : (
                                                      <>
                                                            <tr>
                                                                  <td
                                                                        colSpan={
                                                                              6
                                                                        }
                                                                        className="text-center"
                                                                  >
                                                                        Loading...
                                                                  </td>
                                                            </tr>
                                                      </>
                                                )}
                                          </tbody>
                                    </CardBody>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default FaqRequestList;
