import React from 'react';
import { Col, Form, FormSelect } from 'react-bootstrap';

const FormStatusSelect = ({ classes, title, selected, setStatus }) => {
      return (
            <>
                  <Col lg={classes ? classes : 6}>
                        <Form.Label>
                              {title} <span className="text-danger">*</span>{' '}
                        </Form.Label>
                        <FormSelect
                              value={selected}
                              onChange={(event) =>
                                    setStatus(event.target.value)
                              }
                        >
                              <option>Select One</option>
                              <option value="1">Active</option>
                              <option value="0">Disable</option>
                        </FormSelect>
                  </Col>
            </>
      );
};

export default FormStatusSelect;
