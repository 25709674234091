import React from 'react';
import { MenuSeparator } from '../../navigation/styles';
import MenuItem from '../../metis/MenuItem';
import BlogIcon from '../../dashboard/BlogIcon';
import CheckMultiplePermission from '../../roles/CheckMultiplePermission';
import MenuLink from '../../metis/MenuLink';

const StockManage = ({ userPermissions }) => {
      return (
            <>
                  <CheckMultiplePermission
                        userPermissions={userPermissions}
                        permissions={[
                              'province-list',
                              'province-create',
                              'province-edit',
                              'province-show',
                              'zone-list',
                              'zone-edit',
                              'zone-create',
                              'zone-show',
                              'district-list',
                              'district-create',
                              'district-show',
                              'district-edit'
                        ]}
                  >
                        <MenuSeparator>CRM Manage</MenuSeparator>
                        <CheckMultiplePermission
                              userPermissions={userPermissions}
                              permissions={[
                                    'province-list',
                                    'province-create',
                                    'province-edit',
                                    'province-show',
                                    'zone-list',
                                    'zone-edit',
                                    'zone-create',
                                    'zone-show',
                                    'district-list',
                                    'district-create',
                                    'district-show',
                                    'district-edit'
                              ]}
                        >
                              <MenuItem
                                    title="General Manage"
                                    icon={<BlogIcon />}
                                    links={[
                                          '/dashboard/provinces',
                                          '/dashboard/provinces/create',
                                          '/dashboard/provinces/:provinceId/edit',
                                          '/dashboard/provinces/:provinceId/show',
                                          '/dashboard/zones',
                                          '/dashboard/zones/create',
                                          '/dashboard/zones/:zoneId/edit',
                                          '/dashboard/zones/:zoneId/show',
                                          '/dashboard/district',
                                          '/dashboard/district/create',
                                          '/dashboard/district/:districtId/edit',
                                          '/dashboard/district/:districtId/show'
                                    ]}
                              >
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                'province-list',
                                                'province-create',
                                                'province-edit',
                                                'province-show'
                                          ]}
                                    >
                                          <MenuLink
                                                title="All Province"
                                                link="/dashboard/provinces"
                                                links={[
                                                      '/dashboard/provinces',
                                                      '/dashboard/provinces/create',
                                                      '/dashboard/provinces/:provinceId/edit',
                                                      '/dashboard/provinces/:provinceId/show'
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                'zone-list',
                                                'zone-edit',
                                                'zone-create'
                                          ]}
                                    >
                                          <MenuLink
                                                title="All Zone"
                                                link="/dashboard/zones"
                                                links={[
                                                      '/dashboard/zones',
                                                      '/dashboard/zones/create',
                                                      '/dashboard/zones/:zoneId/edit',
                                                      '/dashboard/zones/:zoneId/show'
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                'district-list',
                                                'district-create',
                                                'district-show',
                                                'district-edit'
                                          ]}
                                    >
                                          <MenuLink
                                                title="All District"
                                                link="/dashboard/districts"
                                                links={[
                                                      '/dashboard/district',
                                                      '/dashboard/district/create',
                                                      '/dashboard/district/:districtId/edit',
                                                      '/dashboard/district/:districtId/show'
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                              </MenuItem>
                        </CheckMultiplePermission>
                  </CheckMultiplePermission>
            </>
      );
};

export default StockManage;
