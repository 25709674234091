import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';

import Progress from 'react-progress-2';
import { toast } from 'react-hot-toast';
import CardHeader from '../../components/common/CardHeader';
import LinkButton from '../../components/common/LinkButton';
import FaqListBox from '../../components/backend/FaqListBox';

const FaqList = () => {
      const [loading, setLoading] = useState(false);
      const [faqs, setFaqs] = useState([]);
      const [totalData, setTotalData] = useState(0);
      const [perPages, setPerPages] = useState(0);
      const [currentPage, setCurrentPage] = useState(0);

      const token = JSON.parse(localStorage.getItem('token'));
      const [limit, setLimit] = useState(10);
      const [search, setSearch] = useState('');

      const fetchFaqs = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/faqs?limit=${limit}&search=${search}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        setFaqs(response.data.faqs);
                        setTotalData(response.data.totalFaqs);
                        setPerPages(response.data.perPages);
                        setCurrentPage(response.data.currentPage);
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            fetchFaqs();
      }, [limit, search]);

      async function getData(pageNumber = 1) {
            window.scrollTo(0, 0);
            setLoading(true);
            Progress.show();
            const url = `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/faqs?page=${pageNumber}&limit=${limit}&search=${search}`;
            await axios(url, {
                  headers: {
                        Authorization: `Bearer ${token}`
                  }
            })
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setFaqs(response.data.faqs);
                              setTotalData(response.data.totalFaqs);
                              setPerPages(response.data.perPages);
                              setCurrentPage(response.data.currentPage);
                        }
                        Progress.hide();
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      }

      return (
            <>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader title="Faq List">
                                          <LinkButton
                                                link="/dashboard/faqs/create"
                                                title="Add New"
                                          />
                                    </CardHeader>
                                    <FaqListBox
                                          setLimit={setLimit}
                                          loading={loading}
                                          items={faqs}
                                          setItems={setFaqs}
                                          fetchData={fetchFaqs}
                                          totalItems={totalData}
                                          perPages={perPages}
                                          currentPage={currentPage}
                                          getData={getData}
                                          setSearch={setSearch}
                                          setLoading={setLoading}
                                    />
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default FaqList;
