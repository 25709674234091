import React from 'react';
import { NavLink, matchPath, useLocation } from 'react-router-dom';

const MenuLink = ({ title, link, links }) => {
      const location = useLocation();

      let isMatchingPath = false;
      if (links) {
            isMatchingPath = links.some(
                  (link) =>
                        !!matchPath(
                              {
                                    path: link
                              },
                              location.pathname
                        )
            );
      }

      return (
            <>
                  <li className={`${isMatchingPath ? 'mm-active' : ''}`}>
                        <NavLink
                              to={link}
                              className={`${
                                    isMatchingPath ? 'sub-active active' : ''
                              }`}
                        >
                              {title}
                        </NavLink>
                  </li>
            </>
      );
};

export default MenuLink;
