import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';

import { useParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import CardHeader from '../../components/common/CardHeader';
import LinkButton from '../../components/common/LinkButton';
import { FormButton, FormInput, FormStatusSelect } from '../../components/form';

const SubscriberEdit = () => {
      const { subscriberId } = useParams();
      const [loadingData, setLoadingData] = useState(false);

      const [email, setEmail] = useState('');
      const [status, setStatus] = useState('');
      const [loading, setLoading] = useState(false);

      const token = JSON.parse(localStorage.getItem('token'));

      const loadData = async () => {
            setLoadingData(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/subscribers/${subscriberId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setEmail(response.data.subscriber.email);
                              setStatus(response.data.subscriber.status);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoadingData(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      const handleForm = async (event) => {
            event.preventDefault();
            setLoading(true);
            const data = {
                  email: email,
                  status: status
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/subscribers/${subscriberId}`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              toast.success(response.data.message);
                        }
                        if (response.data.result === 'error') {
                              if (response.data.message.email) {
                                    toast.error(response.data.message.email);
                              }
                              if (response.data.message.status) {
                                    toast.error(response.data.message.status);
                              }
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      return (
            <>
                  <Form onSubmit={handleForm}>
                        <Row>
                              <Col lg={12}>
                                    <Card>
                                          <CardHeader title="Edit Subscriber">
                                                <LinkButton
                                                      link="/dashboard/subscribers"
                                                      title="Back"
                                                />
                                          </CardHeader>
                                          <Card.Body>
                                                <Row className="g-3">
                                                      <FormInput
                                                            title="Email Address"
                                                            classes={8}
                                                            required={true}
                                                            type="email"
                                                            value={email}
                                                            handleChange={
                                                                  setEmail
                                                            }
                                                      />
                                                      <FormStatusSelect
                                                            title="Status"
                                                            classes={4}
                                                            selected={status}
                                                            setStatus={
                                                                  setStatus
                                                            }
                                                      />
                                                </Row>
                                          </Card.Body>
                                    </Card>

                                    <Card className="mt-4 mb-5">
                                          <Card.Body>
                                                <Col lg={12}>
                                                      <FormButton
                                                            title="Submit"
                                                            loading={loading}
                                                      />
                                                </Col>
                                          </Card.Body>
                                    </Card>
                              </Col>
                        </Row>
                  </Form>
            </>
      );
};

export default SubscriberEdit;
