import React from 'react';

const PageNotFound = () => {
      return (
            <>
                  <div className='text-center' style={{ height: '50vh', padingTop: '20vh' }}>
                        <h4>Page not found</h4>
                  </div>
            </>
      )
}

export default PageNotFound;