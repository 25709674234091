import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Card, Col, ListGroup, Row } from 'react-bootstrap';

import Progress from 'react-progress-2';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import CardHeader from '../../components/common/CardHeader';
import LinkButton from '../../components/common/LinkButton';
import { ItemShow } from '../../components/form';
import FaqListBox from '../../components/backend/FaqListBox';

const FaqTypeShow = () => {
      const { typeId } = useParams();

      const [loading, setLoading] = useState(false);
      const [type, setType] = useState([]);

      const token = JSON.parse(localStorage.getItem('token'));
      const [limit, setLimit] = useState(10);
      const [search, setSearch] = useState('');

      const [faqs, setFaqs] = useState([]);
      const [totalFaqs, setTotalFaqs] = useState(0);
      const [perPages, setPerPages] = useState(0);
      const [currentPage, setCurrentPage] = useState(0);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/faq_types/${typeId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`,
                                    'Content-Type': 'multipart/form-data'
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setType(response.data.type);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });

            setLoading(false);
      };

      const loadCategory = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/faq_types/${typeId}/edit?limit=${limit}&search=${search}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`,
                                    'Content-Type': 'multipart/form-data'
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setFaqs(response.data.faqs);
                              setTotalFaqs(response.data.totalFaqs);
                              setPerPages(response.data.itemsCountPerPage);
                              setCurrentPage(response.data.currentPage);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      async function getData(pageNumber = 1) {
            window.scrollTo(0, 0);
            setLoading(true);
            Progress.show();
            const url = `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/faq_types/${typeId}/edit?page=${pageNumber}&limit=${limit}&search=${search}`;
            await axios(url, {
                  headers: {
                        Authorization: `Bearer ${token}`
                  }
            })
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setFaqs(response.data.faqs);
                              setTotalFaqs(response.data.totalFaqs);
                              setPerPages(response.data.itemsCountPerPage);
                              setCurrentPage(response.data.currentPage);
                        }
                        Progress.hide();
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      }

      useEffect(() => {
            loadCategory();
      }, [limit, search]);

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader title="Category Detail">
                                          <LinkButton
                                                title="Back"
                                                link="/dashboard/faqs/types"
                                          />
                                    </CardHeader>
                                    <Card.Body>
                                          <Row>
                                                <Col lg={7}>
                                                      <ListGroup>
                                                            <ItemShow
                                                                  title="Title"
                                                                  value={
                                                                        type.title
                                                                  }
                                                            />

                                                            <ItemShow
                                                                  title="Date"
                                                                  value={
                                                                        type.date
                                                                  }
                                                            />

                                                            <ItemShow
                                                                  title="User Name"
                                                                  value={
                                                                        type.user_name
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Status"
                                                                  value={
                                                                        type.status ? (
                                                                              <>
                                                                                    <span className="text-success">
                                                                                          Active
                                                                                    </span>
                                                                              </>
                                                                        ) : (
                                                                              <>
                                                                                    <span className="text-warning">
                                                                                          Disable
                                                                                    </span>
                                                                              </>
                                                                        )
                                                                  }
                                                            />
                                                      </ListGroup>
                                                </Col>
                                          </Row>
                                    </Card.Body>
                              </Card>

                              <Card className="mt-4">
                                    <CardHeader title="Faq List" />

                                    <FaqListBox
                                          loading={loading}
                                          items={faqs}
                                          totalItems={totalFaqs}
                                          perPages={perPages}
                                          setLimit={setLimit}
                                          currentPage={currentPage}
                                          getData={getData}
                                          fetchData={loadCategory}
                                          setLoading={setLoading}
                                          setItems={setFaqs}
                                          setSearch={setSearch}
                                    />
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default FaqTypeShow;
