const CheckPermission = ({ userPermissions, permission, children }) => {
      return (
            userPermissions &&
            Array.isArray(userPermissions) &&
            userPermissions.includes(permission) &&
            children
      );
};

export default CheckPermission;
