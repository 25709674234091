import React from 'react';

const CheckListIcon = () => {
      return (
            <>
                  <span className="material-symbols-outlined">list_alt</span>
            </>
      );
};

export default CheckListIcon;
