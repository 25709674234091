import axios from 'axios';
import React, { useEffect, useState } from 'react';
import {
      Card,
      Col,
      ListGroup,
      ListGroupItem,
      Row,
      Spinner
} from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import CardHeader from '../../components/common/CardHeader';
import LinkButton from '../../components/common/LinkButton';
import { ItemShow } from '../../components/form';

const JobShow = () => {
      const { jobId } = useParams();

      const [loading, setLoading] = useState(false);
      const [job, setJob] = useState([]);

      const token = JSON.parse(localStorage.getItem('token'));

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/job_lists/${jobId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setJob(response.data.job);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader title="Job Details">
                                          <LinkButton
                                                title="Back"
                                                link="/dashboard/jobs"
                                          />
                                    </CardHeader>
                                    <Card.Body>
                                          {!loading ? (
                                                <Row>
                                                      <Col lg={6}>
                                                            <ListGroup>
                                                                  <ItemShow
                                                                        title="Title"
                                                                        value={
                                                                              job.title
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Education"
                                                                        value={
                                                                              job.education
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Experince"
                                                                        value={
                                                                              job.experience
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Type"
                                                                        value={
                                                                              job.type
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Salary"
                                                                        value={
                                                                              job.salary
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Deadline"
                                                                        value={
                                                                              job.deadline
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Summary"
                                                                        value={
                                                                              job.summary
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Description"
                                                                        value={
                                                                              <div
                                                                                    dangerouslySetInnerHTML={{
                                                                                          __html: job.description
                                                                                    }}
                                                                              ></div>
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Title"
                                                                        value={
                                                                              job.seo_title
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="SEO Keyword"
                                                                        value={
                                                                              job.seo_keyword
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="SEO Description"
                                                                        value={
                                                                              job.seo_description
                                                                        }
                                                                  />
                                                            </ListGroup>
                                                      </Col>
                                                      <Col lg={6}>
                                                            <ListGroup>
                                                                  <ListGroupItem className="text-center">
                                                                        <div className="d-flex gap-3">
                                                                              {job?.image && (
                                                                                    <div>
                                                                                          <img
                                                                                                src={
                                                                                                      job.image
                                                                                                }
                                                                                                alt={
                                                                                                      job.title
                                                                                                }
                                                                                                className="border p-2 rounded"
                                                                                                style={{
                                                                                                      width: '150px',
                                                                                                      height: '150px',
                                                                                                      objectFit:
                                                                                                            'contain'
                                                                                                }}
                                                                                          />
                                                                                          <div className="mt-2">
                                                                                                Featured
                                                                                                Image
                                                                                          </div>
                                                                                    </div>
                                                                              )}
                                                                              {job?.seo_image && (
                                                                                    <div>
                                                                                          <img
                                                                                                src={
                                                                                                      job.seo_image
                                                                                                }
                                                                                                alt={
                                                                                                      job.title
                                                                                                }
                                                                                                className="border p-2 rounded"
                                                                                                style={{
                                                                                                      width: '150px',
                                                                                                      height: '150px',
                                                                                                      objectFit:
                                                                                                            'contain'
                                                                                                }}
                                                                                          />
                                                                                          <div className="mt-2">
                                                                                                SEO
                                                                                                Image
                                                                                          </div>
                                                                                    </div>
                                                                              )}
                                                                        </div>
                                                                  </ListGroupItem>
                                                                  <ItemShow
                                                                        title="Status"
                                                                        value={
                                                                              job.status ===
                                                                              1 ? (
                                                                                    <>
                                                                                          <label className="text-success p-2">
                                                                                                Active
                                                                                          </label>
                                                                                    </>
                                                                              ) : (
                                                                                    <>
                                                                                          <label className="text-danger p-2">
                                                                                                Disable
                                                                                          </label>
                                                                                    </>
                                                                              )
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Created By"
                                                                        value={
                                                                              job.user_name
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Date"
                                                                        value={
                                                                              job.date
                                                                        }
                                                                  />
                                                            </ListGroup>
                                                      </Col>
                                                </Row>
                                          ) : (
                                                <>
                                                      <div className="h-25 text-center">
                                                            <Spinner />
                                                      </div>
                                                </>
                                          )}
                                    </Card.Body>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default JobShow;
