import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import ListGroup from 'react-bootstrap/ListGroup';
import { Card, Col, Row } from 'react-bootstrap';
import CardHeader from '../../components/common/CardHeader';
import LinkButton from '../../components/common/LinkButton';
import { ItemShow } from '../../components/form';
import toast from 'react-hot-toast';

const FaqRequestShow = () => {
      const { faqId } = useParams();
      const [faq, setFaq] = useState([]);

      useEffect(() => {
            const loadData = async () => {
                  await axios
                        .get(
                              `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/faq_requests/${faqId}`,
                              {
                                    headers: {
                                          Authorization: `Bearer ${JSON.parse(
                                                localStorage.getItem('token')
                                          )}`,
                                          'Content-Type': 'multipart/form-data'
                                    }
                              }
                        )
                        .then((response) => {
                              if (response.data.result === 'success') {
                                    setFaq(response.data.faq);
                              }
                        })
                        .catch((error) => {
                              toast.error(error.message);
                        });
            };
            loadData();
      }, []);

      return (
            <>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader title="Faq Details">
                                          <LinkButton
                                                link="/dashboard/faqs/requests"
                                                title="Back"
                                          />
                                    </CardHeader>
                                    <Card.Body>
                                          <Row>
                                                <Col lg={8}>
                                                      <ListGroup>
                                                            <ItemShow
                                                                  title="Asked By "
                                                                  value={
                                                                        faq.name
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Email "
                                                                  value={
                                                                        faq.email
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Phone "
                                                                  value={
                                                                        faq.phone
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Question"
                                                                  value={
                                                                        faq.question
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Answer"
                                                                  value={
                                                                        faq.answer
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Type"
                                                                  value={
                                                                        faq.category
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Status"
                                                                  value={
                                                                        faq.status ? (
                                                                              <>
                                                                                    <span className="text-success">
                                                                                          Active
                                                                                    </span>
                                                                              </>
                                                                        ) : (
                                                                              <>
                                                                                    <span className="text-warning">
                                                                                          Disable
                                                                                    </span>
                                                                              </>
                                                                        )
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Date"
                                                                  value={
                                                                        faq.date
                                                                  }
                                                            />
                                                      </ListGroup>
                                                </Col>
                                          </Row>
                                    </Card.Body>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default FaqRequestShow;
