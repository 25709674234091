import React from 'react';

const NotificationIcon = () => {
      return (
            <>
                  <span className="material-icons">notifications</span>
            </>
      );
};

export default NotificationIcon;
