import React from 'react';
import { FormCheck } from 'react-bootstrap';

const FormStatus = ({ item, handleStatus, type }) => {
      var checked;
      if (type) {
            checked = type;
      } else {
            checked = item?.status === 1 ? true : false;
      }
      return (
            <>
                  <FormCheck
                        type="switch"
                        id="custom-switch"
                        defaultChecked={checked}
                        onClick={() => handleStatus(item)}
                  />
            </>
      );
};

export default FormStatus;
